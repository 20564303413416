import { Container, Row,Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';
export function Service() {
    return (
      <>

    <div className="application">
        <Helmet>
        <meta name="description" content="Explore the services offered by Momade Júnior, including graphic design, web design, frontend development, and more." />
        </Helmet>
      </div>

      <Container className="service">
        <Row>
          <Col sm={4}>
              <h1>Services</h1>
          </Col>

          <Col sm={2} className="title-description">
              Design graphic
          </Col>

          <Col sm={6} className="short-description top-desc">
              Creation of visual materials such as logos, typography, etc.
          </Col>


            {/*Two row*/}


            <Col sm={4}>
              <h1></h1>
          </Col>

          <Col sm={2} className="title-description">
                Brand Identity
          </Col>

          <Col sm={6} className="short-description">
              Development of logos, typography, and visual elements to represent the company's identity.
          </Col>



          {/*Two row*/}

          <Col sm={4}>
              <h1></h1>
          </Col>

          <Col sm={2} className="title-description">
              Web Design
          </Col>

          <Col sm={6} className="short-description">
              Creation of visual interfaces for applications and websites.
              Frontend Development - Building interactive and visually appealing websites for enhanced user interaction.
          </Col>


           {/*Two row*/}

           <Col sm={4}>
              <h1></h1>
          </Col>

          <Col sm={2} className="title-description">
          Other Services 
          </Col>

          <Col sm={6} className="short-description">
          If you have any other specific services in mind, please let me know so that I can assist you more accurately.
          </Col>

        </Row>
      </Container>
      </>
    );
  }
  
  
  