import { Container, Row, Col } from "react-bootstrap";
import loader from "../video/stepfoward.mp4";
import cfc from "../video/cfc.mp4";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import {Helmet} from 'react-helmet';
export function Lart() {

  useEffect(() => {
    AOS.init({duration: 2000});
});

  return (
    <>
    <div className="application">
            <Helmet>
                <style>{'body { background-color:black; }'}</style>
                <style>{'.nav-link a{color:#fff !important;'}</style>
                <style>{'.nav-link a:hover{color:blue !important;'}</style>
                <style>{'.navbar-brand a {color: #fff !important;text-decoration: none;'}</style>
            </Helmet>
        </div>
      <Container>
        <Row>

        <Col sm={12} className="title-project-inside">
          <h1>L'art</h1>
          <h5>Mobiliarios</h5>
        </Col>

          <Col sm={12}>
            <img data-aos="fade-up"
              src="img/lart-01.jpg"
              alt="Momade Junior"
              className="img-fluid project-img m-top-02"
            />
          </Col>

          
          <Col sm={6}>
          <img data-aos="fade-up-right"
              src="img/lart-03.jpg"
              alt="Momade Junior"
              className="img-fluid project-img m-top"
            />
          </Col>

          <Col sm={6}>
            <img data-aos="fade-up-left"
              src="img/lart-02.jpg"
              alt="Momade Junior"
              className="img-fluid unique m-top"
            />

            <p className="desc-section " data-aos="fade-up">L'Arts foi um projecto de de redesign do logotipo, o intuito de logotipo e transmitir a sensacao de ter ter um produto moderno e simples com um conceito minimalistico, que seja usado em diversos locais escritorios, em decoracoes e ter um produto premium</p>

          <img

              data-aos="fade-up-left"
              src="img/lart-04.jpg"
              alt="Momade Junior"
              className="img-fluid m-top"
            />
          </Col>


        </Row>
      </Container>


      <Container>
        <Row>
          <Col className="project-showcase">
            <img 
              data-aos="fade-up"
              src="img/lart-05.jpg"
              alt="Momade Junior"
              className="img-fluid project-img larts-05"
            />

<img          data-aos="fade-up-left"
              src="img/typography.jpg"
              alt="Momade Junior"
              className="img-fluid project-img last-img"
            />
          </Col>

          
        </Row>
      </Container>
    </>
  );
}
