import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

import emailjs from '@emailjs/browser';

export function Work() {
  const formRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_g6999ia', 'template_d9xe2dl', formRef.current, 'OAXV_TY-Aek8NjEhS')
      .then((result) => {
        console.log(result.text);
        setIsSubmitted(true);
      })
      .catch((error) => {
        console.error(error.text);
      });

    formRef.current.reset();
  };

  return (
    <>
       <div className="application">
        <Helmet>
          <style>{'body { background-color:black; }'}</style>
          <style>{'.nav-link a{color:#fff !important;}'}</style>
          <style>{'.nav-link a:hover{color:blue !important;}'}</style>
          <style>{'.navbar-brand a{color:#fff !important;text-decoration: none;}'}</style>
          <style>{'h2{color:#fff;}'}</style>
        </Helmet>
      </div>

      <Container className="container">
        <Row>
          <div className="contact-box">
            {isSubmitted ? (
              <div className="success-message">
              <h3 className='sucess-message'>Message sent successfully!</h3>
              </div>
              
            ) : (
              <form ref={formRef} onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Name" required minLength="2" maxLength="50" />
                <input type="text" name="subject" placeholder="Subject" maxLength="100" />
                <input type="email" name="email" placeholder="E-mail" required />
                <textarea name="message" placeholder="Message" required minLength="10" maxLength="500" />
                <input type="submit" value="Send" />
              </form>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
}
