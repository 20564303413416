import { useState, useRef, useEffect } from 'react';
import {Container,Row,Col} from "react-bootstrap";
import {Helmet} from 'react-helmet';

export function Mouse() {
  const [positions, setPositions] = useState([]);

  const imageRefs = useRef([]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = e.clientX;
      const y = e.clientY;
  
      setPositions((prevPositions) => {
        const newPositions = [...prevPositions];
        imageRefs.current.forEach((imgRef, i) => {
          newPositions[i] = {
            left: x,
            top: y,
          };
        });
        return newPositions;
      });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleImageLoad = (i) => {
    const imgRef = imageRefs.current[i];
    const container = imgRef.parentElement;
    const { width, height } = imgRef.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
  
    setPositions((prevPositions) => {
      const newPositions = [...prevPositions];
      newPositions[i] = {
        left: (containerRect.width - width) / 2 - containerRect.left,
        top: (containerRect.height - height) / 2 - containerRect.top,
      };
      return newPositions;
    });
  };
  

  return (
    <>

<div className="application">
            <Helmet>
                <style>{'body { background-color:black; }'}</style>
                <style>{'.nav-link a{color:#fff !important;}'}</style>
                <style>{'.nav-link a:hover{color:blue !important;}'}</style>
                <style>{'.navbar-brand a{color:#fff !important;text-decoration: none;}'}</style>
            </Helmet>
        </div>

        <Container>
        <Row>
            <Col sm={4} data-aos="fade-up">
            <img
              src="momadejunior02.jpg"
              alt="Momade Junior"
              className="img-fluid img-profile"
            />
            <p className="description-02">2017<br></br>
              webdesigner/ ux designer<br></br>
              Maputo</p>
            </Col>
            <Col sm="6" data-aos="fade-right">
                <h1 className="title">webdesigner
                    from the bottom
                </h1>
            </Col>
        </Row>
    </Container>
{/*short description*/}


    <Container>
        <Row>
            <Col sm={4}>

            </Col>

            <Col sm={8} data-aos="fade-right">
                <p className="who-is-me">Momade Júnior is a mozambican, Self - taught in Graphic Design and web development, with 4 years of experience, he dedicate himself in developing flowcharts, sitemap, and designing web and app interfaces that can provide an excellent user experience due to the navigability of the site, the arrangement of information, the use of colors, images, videos, the architecture of the information as a whole.</p>
            </Col>
        </Row>
    </Container>


    <Container>
      <div className='img-btn-project'>
        <a href="#"  className='link-project'>
          <h1>MMF</h1>
          <img
            ref={(el) => (imageRefs.current[0] = el)}
            src="img/1.jpg"
            alt="img-cover"
            className="follow-img"
            onLoad={() => handleImageLoad(0)}
            style={positions[0]}
          />
        </a>
      </div>

      <div className='img-btn-project'>
        <a href="#" className='link-project'>
          <h1>ABSA virtual Run</h1>
          <img
            ref={(el) => (imageRefs.current[1] = el)}
            src="img/2.jpg"
            alt="img-cover"
            className="follow-img"
            onLoad={() => handleImageLoad(1)}
            style={positions[1]}
          />
        </a>
      </div>

      
      <div className='img-btn-project'>
        <a href="#" className='link-project'>
          <h1>ABSA virtual Run</h1>
          <img
            ref={(el) => (imageRefs.current[2] = el)}
            src="img/3.jpg"
            alt="img-cover"
            className="follow-img"
            onLoad={() => handleImageLoad(2)}
            style={positions[2]}
          />
        </a>
      </div>

      
      <div className='img-btn-project'>
        <a href="#" className='link-project'>
          <h1>+emprego</h1>
          <img
            ref={(el) => (imageRefs.current[3] = el)}
            src="img/4.jpg"
            alt="img-cover"
            className="follow-img"
            onLoad={() => handleImageLoad(3)}
            style={positions[3]}
          />
        </a>
      </div>
</Container>

    </>
  )}