import "bootstrap/dist/css/bootstrap.min.css";
import {Helmet} from 'react-helmet';
import {Container,Row,Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import { motion } from "framer-motion"

export function Portfolio() {
  return (
   <>
  
 <div className="application">
            <Helmet>
                <style>{'body { background-color:black;}'}</style>
                <style>{'.nav-link a{color:#fff !important;'}</style>
                <style>{'.nav-link a:hover{color:blue !important;'}</style>
                <style>{'.navbar-brand a{color:#fff !important;text-decoration: none;}'}</style>
            </Helmet>
        </div>
        
   <Container>
        <Row>
            <Col className="section">
              <h1 className="section-heading">Logofolio</h1>
              </Col>
        </Row>

        <Row>
            <Col className="border-left" sm={4}>
              <Link to="/project">
                  <div className="testfy">
                  <p className="title-project">stepfowartd<br/>
                  <span className="clients">Joseph NortHwood</span></p>
                  </div>
                    <div className="img-main-project">
                      <img src="./featured-img/featured-img-01.jpg" alt="" className="img-fluid project-img"/>
                    </div>
                    <div className="victim">
                    <p className="project-tip">Brand Identity</p>
                      </div>
                </Link>
            </Col>
            <Col className="border-left" sm={4}>
            <Link to="/circular">
                  <div className="testfy">
                  <p className="title-project">Circular Futebol Clube<br/>
                  <span className="clients">cfc</span></p>
                  </div>
                    <div className="img-main-project">
                      <img src="./featured-img/featured-img-02.jpg" alt="" className="img-fluid project-img"/>
                    </div>
                    <div className="victim">
                    <p className="project-tip">Brand Identity</p>
                      </div>
                </Link>
            </Col>

            <Col className="border-left" sm={4}>
            <Link to="/lart">
                  <div className="testfy">
                  <p className="title-project">lart<br/>
                  <span className="clients">Joseph NortHwood</span></p>
                  </div>
                    <div className="img-main-project">
                      <img src="./featured-img/featured-img-03.jpg" alt="" className="img-fluid project-img"/>
                    </div>
                    <div className="victim">
                    <p className="project-tip">Brand Identity</p>
                      </div>
                </Link>
            </Col>

            
        </Row>

        <Row>
            <Col className="border-left" sm={4}>
                
            <Link to="/mola">
                  <div className="testfy">
                  <p className="title-project">Mola<br/>
                  <span className="clients">Turbohost</span></p>
                  </div>
                    <div className="img-main-project">
                      <img src="./featured-img/featured-img-04.jpg" alt="" className="img-fluid project-img"/>
                    </div>
                    <div className="victim">
                    <p className="project-tip">Brand Identity</p>
                      </div>
                </Link>
               
            </Col>
            <Col className="border-left" sm={4}>
            <Link to="/mun">
                  <div className="testfy">
                  <p className="title-project">Munguanaze<br/>
                  <span className="clients">Logotipo</span></p>
                  </div>
                    <div className="img-main-project">
                      <img src="./img/billboard.jpg" alt="" className="img-fluid project-img"/>
                    </div>
                    <div className="victim">
                    <p className="project-tip">Brand Identity</p>
                      </div>
                </Link>
            </Col>

            <Col className="border-left" sm={4}>
            <Link to="/last">
                  <div className="testfy">
                  <p className="title-project">Projecto<br/>
                  <span className="clients">Cliente</span></p>
                  </div>
                    <div className="img-main-project">
                      <img src="./img/stepfoward-04.png" alt="" className="img-fluid project-img"/>
                    </div>
                    <div className="victim">
                    <p className="project-tip">Brand Identity</p>
                      </div>
                </Link>
            </Col>

            
        </Row>
    </Container>

   
    </>
  );
}




