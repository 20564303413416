import { Container, Row, Col } from "react-bootstrap";
import loader from "../video/stepfoward.mp4";
import {Helmet} from 'react-helmet';
export function Project() {
  return (
    <>
    <div className="application">
            <Helmet>
                <style>{'body { background-color:black; }'}</style>
                <style>{'.nav-link a{color:#fff !important;'}</style>
                <style>{'.nav-link a:hover{color:blue !important;'}</style>
            </Helmet>
        </div>
      <Container>
        <Row>

        <Col sm={12} className="title-project-inside">
          <h1>stepfoward</h1>
          <h5>JOSEPHNORTHwood</h5>
        </Col>
          <Col>
            <img
              src="img/stepfoward.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />
            <img
              src="img/stepfoward-02.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />

            <img
              src="img/stepfoward-03.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />

            <img
              src="img/stepfoward-04.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />

            <video
              src={loader}
              alt="Momade Junior"
              autoplay="true"
              loop="true"
              className="img-fluid project-img video"
            />

            <img
              src="img/stepfoward-05.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />

            <img
              src="img/stepfoward-06.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />

            <img
              src="img/stepfoward-07.png"
              alt="Momade Junior"
              className="img-fluid project-img"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
