/*import logo from './logo.svg';*/
import "./App.css";
import React, { useState,useEffect } from 'react';
/*import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';*/
import "bootstrap/dist/css/bootstrap.min.css";
import {Navbar,Nav,Container} from "react-bootstrap";
import {Portfolio} from "./portfolio.js";
import {Work} from "./components/work.js";
import {About} from "./components/about.js";
import {Home} from "./components/home.js";
import {Service} from "./components/service.js";
import {Project} from "./components/projects.js";
import {Circular} from "./components/cfc.js";
import {Lart} from "./components/lart.js";
import {Mola} from "./components/mola.js";
import {Mun} from "./components/mun.js";
import { Last } from "./components/last.js";
import { Mouse } from "./components/mousehove.js";
import { Helmet } from 'react-helmet';




import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles



function App() {

  useEffect(() => {
    AOS.init({duration: 2000});
});
  return (
    <>
    <Router>
    <Navbar bg="light" expand="lg"  fixed="top">
        <Container className="navigation-bar">
          <Navbar.Brand href="#home"><Link to="/home">MOMADE JUNIOR</Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link><Link to="/service">Service</Link></Nav.Link>
              <Nav.Link><Link to="/about">About</Link></Nav.Link>
              {/*<Nav.Link><Link to="/portfolio">Portfolio</Link></Nav.Link>*/}
              {/*<Nav.Link><Link to="/mouse">Mouse</Link></Nav.Link>*/}
              <Nav.Link className="work">
                <Link to="/work">lets work together</Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
       <Routes>
       <Route path="/" element={<Home/>}/>
       <Route path="/home" element={<Home/>}/>
       <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/work" element={<Work/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/service" element={<Service/>} />
        <Route path="/project" element={<Project/>} />
        <Route path="/circular" element={<Circular/>} />
        <Route path="/lart" element={<Lart/>} />
        <Route path="/mola" element={<Mola/>} />
        <Route path="/mun" element={<Mun/>} />
        <Route path="/last" element={<Last/>} />
        <Route path="/mouse" element={<Mouse/>} />
      </Routes>
    </Router>
        </>
  );
  }

  

export default App;
