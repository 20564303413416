import { Container, Row, Col } from "react-bootstrap";
import loader from "../video/stepfoward.mp4";
import cfc from "../video/cfc.mp4";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import {Helmet} from 'react-helmet';
export function Circular() {

  useEffect(() => {
    AOS.init({duration: 2000});
});

  return (
    <>
    <div className="application">
            <Helmet>
                <style>{'body { background-color:black; }'}</style>
                <style>{'.nav-link a{color:#fff !important;'}</style>
                <style>{'.nav-link a:hover{color:blue !important;'}</style>
                <style>{'.navbar-brand a {color: #fff !important;text-decoration: none;'}</style>
            </Helmet>
        </div>
      <Container>
        <Row>

        <Col sm={12}>
          <h1>Circular Futebol Clube</h1>
          <h2>An amateur football Clube</h2>
        </Col>

          <Col>
            <img
              src="img/Sports_Jersey.jpg"
              alt="Momade Junior"
              className="img-fluid project-img"
            />
            <img
              src="img/football-flag-stadium.jpg"
              alt="Momade Junior"
              className="img-fluid project-img"
              data-aos="fade-up"
            />

            <img
              src="img/football-mockup-two.png"
              alt="Momade Junior"
              className="img-fluid project-img"
              data-aos="fade-up"
            />

        
            <video
              src={cfc}
              alt="Momade Junior"
              autoplay="true"
              loop="true"
              className="img-fluid project-img video"
              data-aos="fade-up"
            />

          </Col>
        </Row>
      </Container>


      <Container>
        <Row>
          <Col className="project-showcase">
            <img
              src="img/Sports_Jersey.jpg"
              alt="Momade Junior"
              className="img-fluid project-img"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
