import {Container,Row,Col,} from "react-bootstrap";
import React, { useState,useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";



export function Home() {

  useEffect(() => {
    AOS.init({duration: 1000});
});

  return (
<>
      <Container maxWidth="sm" className="fluid mg-top home-page">
        <Row>
          <Col sm={8}>
            <div className="" >
              <h1 className="name" data-aos="fade-right">Momade Júnior</h1>

              <img src="../img/eu.png" alt="momade" className="img-fluid mobile-me"/>
              <h4 className="description" data-aos="fade-up">
                Momade júnior<span className="span-desc-01"></span>©<br />
                webdesigner/ ux designer
                <br />
                Maputo<span className="span-desc-02"></span>2022
              </h4>

             <div className="social-icon">
              <a href="https://dribbble.com/Momadejunior" target="_blank" className="about-link"><span className="plus"><img src="../img/dribble-big-logo.png" alt="dribble" /></span></a>
                <a href="https://www.linkedin.com/in/momade-j%C3%BAnior-870123bb/" target="_blank" className="about-link"><span className="plus"><img src="../img/linkedin.png" alt="linkedin" /></span></a>
              </div> 
            </div>
          </Col>

        <Col sm={4} data-aos="fade-left" className="effect-unset">
               <img src="../img/eu.png" alt="momade" className="img-fluid desktop-me"/>
          </Col>

          <Col sm={1}></Col>
        </Row>
      </Container>  
</>
  );
  
  }
