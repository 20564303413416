import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

export function About() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [positions, setPositions] = useState([]);

  const imageRefs = useRef([]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const x = e.clientX;
      const y = e.clientY;

      setPositions((prevPositions) => {
        const newPositions = [...prevPositions];
        imageRefs.current.forEach((imgRef, i) => {
          newPositions[i] = {
            left: x,
            top: y,
          };
        });
        return newPositions;
      });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleImageLoad = (i) => {
    const imgRef = imageRefs.current[i];
    const { width, height } = imgRef.getBoundingClientRect();
    setPositions((prevPositions) => {
      const newPositions = [...prevPositions];
      newPositions[i] = {
        left: -width / 2,
        top: -height / 2,
      };
      return newPositions;
    });
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <style>{'body { background-color:black; }'}</style>
          <style>{'.nav-link a{color:#fff !important;}'}</style>
          <style>{'.nav-link a:hover{color:blue !important;}'}</style>
          <style>{'.navbar-brand a{color:#fff !important;text-decoration: none;}'}</style>
          <style>{'h2{color:#fff;}'}</style>
        </Helmet>
      </div>

      <Container className='margin-top'>
        <Row>
          <Col sm={4} data-aos="fade-up">
            <img
              src="momadejunior02.jpg"
              alt="Momade Junior"
              className="img-fluid img-profile"
              ref={(ref) => (imageRefs.current[0] = ref)}
              onLoad={() => handleImageLoad(0)}
            />
            <p className="description-02">
              2017
              <br />
              webdesigner/ ux designer
              <br />
              Maputo
            </p>
          </Col>
          <Col sm="6" data-aos="fade-right">
            <h1 className="title">
              webdesigner
              <br />
              from the bottom
            </h1>
          </Col>
        </Row>
      </Container>

      {/* Short description */}
      <Container>
        <Row>
          <Col sm={4}></Col>

          <Col sm={8} data-aos="fade-right">
            <p className="who-is-me">
              Momade Júnior is a Mozambican self-taught Graphic Designer and Web Developer with 4 years of experience. He dedicates himself to developing flowcharts, sitemaps, and designing web and app interfaces that provide an excellent user experience through site navigability, information arrangement, use of colors, images, videos, and overall information architecture.
            </p>
          </Col>
        </Row>
      </Container>

      <div className="container timeline-container" data-aos="fade-up">
        <div className="timeline-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <p className="year-of-work">2020</p>
              </div>
              <div className="col-md-8">
                <p className="work-time">Web Designer - Anima estudio criativo</p>
              </div>
            </div>
          </div>
        </div>

        <div className="timeline-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <p className="year-of-work">2019</p>
              </div>
              <div className="col-md-8">
                <p className="work-time">Graphic Designer - Puzzle Sticker</p>
              </div>
            </div>
          </div>
        </div>

        <div className="timeline-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <p className="year-of-work">2018</p>
              </div>
              <div className="col-md-8">
                <p className="work-time">Graphic Designer and Social Media Manager - Brainstorming Academy</p>
              </div>
            </div>
          </div>
        </div>

        <div className="timeline-list">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <p className="year-of-work">2017</p>
              </div>
              <div className="col-md-8">
                <p className="work-timeline">Freelancer</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container collab-work">
        <h2>Recent collab work</h2>
      </div>

      {/* Skills */}
      <div className="container skills" data-aos="fade-up">
        <div className="row">
          {/* First row */}
          <div className="col-md-6 line">
            <h1>MFF</h1>
            <p>
              In the MFF project, my role was as a frontend developer in 2021. As a frontend developer, I was responsible for implementing the visual and interactive elements of the project's website or application. I worked closely with the design team to translate their mockups or wireframes into functional user interfaces. My tasks involved writing HTML, CSS, and JavaScript code to create the structure, layout, and interactivity of the frontend components.
            </p>
          </div>

          <div className="col-md-6 img-project line">
            <div className="img-box-project">
             <a href='http://mff2021.maputofastforward.com/' target='_blank'><img src="img/1.jpg" alt="project" className="img-fluid project" ref={(ref) => (imageRefs.current[1] = ref)} onLoad={() => handleImageLoad(1)} /></a>
            </div>
          </div>

          {/* Two row */}
          <div className="col-md-6 line">
            <h1>ABSA Run</h1>
            <p>
              In the ABSA Run project, I served as the UI/UX designer, responsible for designing the user interface and creating the flowchart sitemap. I collaborated closely with stakeholders to understand their requirements and objectives. I created wireframes and prototypes to establish the layout and interaction flow of the application. Additionally, I designed the visual components, considering factors such as color schemes and typography. Simultaneously, I developed a flowchart sitemap to outline the application's structure and navigation.
            </p>
          </div>

          <div className="col-md-6 img-project line">
            <div className="img-box-project">
            <a href='https://meiamaratonademaputo.co.mz/' target='_blank'><img src="img/2.jpg" alt="project" className="img-fluid project" ref={(ref) => (imageRefs.current[2] = ref)} onLoad={() => handleImageLoad(2)} /></a>
            </div>
          </div>

          {/* Third row */}
          <div className="col-md-6 line">
            <h1>Joli Vilas</h1>
            <p>
              In the Joli Vilas project, I served as a UI/UX designer. My role focused on creating an intuitive and visually pleasing user interface. I collaborated with stakeholders, conducted user research, and designed wireframes and prototypes. I ensured the information architecture was organized effectively and paid attention to interactive elements, color schemes, and typography. Throughout the project, I worked closely with the development team to ensure successful implementation of the UI/UX designs.
            </p>
          </div>

          <div className="col-md-6 img-project line">
            <div className="img-box-project">
            <a href='http://jolivillas.com/' target='_blank'><img src="img/3.jpg" alt="project" className="img-fluid project" ref={(ref) => (imageRefs.current[3] = ref)} onLoad={() => handleImageLoad(3)} /></a>
            </div>
          </div>

          {/* Fourth row */}
          <div className="col-md-6 line">
            <h1>+Emprego</h1>
            <p>
              In the "+Emprego" project, my role was as a UI/UX designer. My primary focus was on creating visually appealing and user-friendly interfaces for the project. I collaborated with stakeholders to gather requirements and understand the project's goals and objectives. I conducted user research, designed wireframes and prototypes, and incorporated feedback to refine the designs. I paid attention to information organization, color schemes, typography, and overall user experience. I worked closely with the development team to ensure the successful implementation of the UI/UX designs.
            </p>
          </div>

          <div className="col-md-6 img-project line">
            <div className="img-box-project">
              <a href='https://maisemprego.org.mz/' target='_blank'><img src="img/4.jpg" alt="project" className="img-fluid project" ref={(ref) => (imageRefs.current[4] = ref)} onLoad={() => handleImageLoad(4)} /></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
